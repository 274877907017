import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { api, apiBasepath } from 'src/config';
import { prepareAuthHeaders } from './helper';

const { users } = apiBasepath;
export const authEventApi = createApi({
  reducerPath: 'authEventApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${api.endpoints.admin}${users}`,
    prepareHeaders: prepareAuthHeaders,
  }),
  endpoints: (builder) => ({
    getUserAuthEvents: builder.query({
      query: (params) => ({
        url: `/adminAuthEvents`,
        params,
        method: 'GET',
      }),
    }),
    updatePasswordReset: builder.mutation({
      query: (payload) => ({
        url: '/adminResetPassword',
        method: 'PUT',
        body: payload,
      }),
    }),
    updateMFAReset: builder.mutation({
      query: (payload) => ({
        url: '/adminResetUser',
        method: 'POST',
        body: payload,
      }),
    }),
    updateFeedbackValue: builder.mutation({
      query: (payload) => ({
        url: `/adminAuthEvents`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetUserAuthEventsQuery,
  useUpdateFeedbackValueMutation,
  useUpdatePasswordResetMutation,
  useUpdateMFAResetMutation,
} = authEventApi;
