import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { api, apiBasepath } from 'src/config';
import { prepareAuthHeaders } from './helper';

const { users } = apiBasepath;
export const clientListApi = createApi({
  reducerPath: 'clientListApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${api.endpoints.admin}${users}`,
    prepareHeaders: prepareAuthHeaders,
  }),
  keepUnusedDataFor: 60,
  endpoints: (builder) => ({
    getClientList: builder.query({
      query: () => ({
        url: '/userbalance?getAll=true&disableZeroBalance=true',
        method: 'GET',
      }),
      transformResponse: (res) => ({
        list: res,
        pagination: {
          total: res.length,
          pageSize: 15,
        },
      }),
    }),
    getClientListUserSetting: builder.query({
      query: (noProjection) => {
        let queryStringParam = '';
        if (!noProjection) {
          queryStringParam = '&ProjectionExpression=username';
        }
        if (typeof noProjection === 'string') {
          queryStringParam = noProjection;
        }
        return {
          url: `/usersetting?getAll=true${queryStringParam}`,
          method: 'GET',
        };
      },
      transformResponse: (res) => ({
        list: res,
        pagination: {
          total: res.length,
          pageSize: 15,
        },
      }),
    }),
    getClientListByUsername: builder.query({
      query: ({ username, accountId }) => ({
        url: `/userbalance?username=${username}` + (accountId ? `&accountId=${accountId}` : ''),
        method: 'GET',
      }),
    }),
    updateDeposit: builder.mutation({
      query: (payload) => ({
        url: `/userbalance`,
        method: 'PUT',
        body: payload,
      }),
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            clientListApi.util.updateQueryData('getClientList', undefined, (draft) => {
              const findIndex = draft.list.findIndex((ele) => ele.username === payload.username);
              draft.list[findIndex].depositAddress[data.symbol] = data.depositAddress;
              draft.list[findIndex].tag[data.symbol] = data.tag;
            }),
          );
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      },
    }),
  }),
});

export const {
  useGetClientListQuery,
  useGetClientListUserSettingQuery,
  useGetClientListByUsernameQuery,
  useGetOpenPriceQuery,
  useUpdateDepositMutation,
} = clientListApi;
