import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { api, apiBasepath } from 'src/config';
import { prepareAuthHeaders, transformNonMDDataQuery } from './helper';

const { users } = apiBasepath;

export const marketDataApi = createApi({
  reducerPath: 'marketDataApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${api.endpoints.admin}${users}`,
    prepareHeaders: prepareAuthHeaders,
  }),
  tagTypes: ['exchangeList', 'whitelistAssetTiers'],
  endpoints: (builder) => ({
    getAquaXData: builder.query({
      query: () => ({
        url: `/config?aquaXHedge=true`,
        method: 'GET',
      }),
    }),
    updateAquaXActive: builder.mutation({
      query: (payload) => ({
        url: `/config`,
        method: 'PUT',
        body: payload,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            marketDataApi.util.updateQueryData('getAquaXData', undefined, (draft) => {
              Object.assign(draft, data);
            }),
          );
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      },
    }),
    updateNonMDData: builder.mutation({
      query: (payload) => ({
        url: `/config`,
        method: 'PUT',
        body: payload,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            marketDataApi.util.updateQueryData('getNonMDData', undefined, (draft) => {
              draft.splice(0, draft.length, ...transformNonMDDataQuery(data));
            }),
          );
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      },
    }),
    getNonMDData: builder.query({
      query: () => ({
        url: `/config?paramName=nonMDTickers`,
        method: 'GET',
      }),
      transformResponse: transformNonMDDataQuery,
    }),
    getWhitelistAssetTiers: builder.query({
      query: () => ({
        url: '/config/whitelistAssetTiers',
        method: 'GET',
      }),
      providesTags: ['whitelistAssetTiers'],
    }),
    createWhitelistAssetTier: builder.mutation({
      query: (payload) => ({
        url: '/config/whitelistAssetTiers',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['whitelistAssetTiers'],
    }),
    removeWhitelistAssetTier: builder.mutation({
      query: (payload) => ({
        url: '/config/whitelistAssetTiers',
        method: 'DELETE',
        body: payload,
      }),
      invalidatesTags: ['whitelistAssetTiers'],
    }),
    updateAssetPairStatus: builder.mutation({
      query: (payload) => ({
        url: '/config/whitelistAssetTiers',
        method: 'PUT',
        body: payload,
      }),
      async onQueryStarted(context, { dispatch, queryFulfilled }) {
        const updateResult = dispatch(
          marketDataApi.util.updateQueryData('getWhitelistAssetTiers', undefined, (draft) => {
            return draft.map((d) => {
              if (d.paramName === context.tierName) {
                const newTickers = d.tickers.map((t) => {
                  return t.pair === context.assetPair
                    ? {
                        ...t,
                        status: context.status,
                      }
                    : t;
                });
                return {
                  ...d,
                  tickers: newTickers,
                };
              }
              return d;
            });
          }),
        );
        try {
          await queryFulfilled;
        } catch (err) {
          updateResult.undo();
        }
      },
    }),
  }),
});

export const {
  useGetAquaXDataQuery,
  useUpdateAquaXActiveMutation,
  useGetNonMDDataQuery,
  useUpdateNonMDDataMutation,
  useGetWhitelistAssetTiersQuery,
  useCreateWhitelistAssetTierMutation,
  useRemoveWhitelistAssetTierMutation,
  useUpdateAssetPairStatusMutation,
} = marketDataApi;
