import { generateClient } from 'aws-amplify/api';
const client = generateClient();
export async function query(options, variables) {
  return client.graphql({
    query: options,
    variables: { ...variables },
  });
}

export async function subscribe(subscription, variables, subFunction) {
  return client
    .graphql({
      query: subscription,
      variables: { ...variables },
    })
    .subscribe({
      next: ({ provider, value }) => subFunction(provider, value),
      // eslint-disable-next-line no-console
      error: (error) => console.warn(error),
    });
}
