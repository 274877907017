import React, { useEffect, useRef, useMemo } from 'react';
import { getExpirationTime } from '@utils/sessionExpirationStorage';
import { getTimeLocaleString } from '@utils/dateTime';
import { notification, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setIsSessionExpiring } from '@app/services/slice/session';
import { useLogout } from './useLogout';
import { store } from '@app/store';

type RootState = ReturnType<typeof store.getState>;

interface UseSessionExpirationProps {
  expirationTime: string | null;
  isSessionExpiring: boolean;
  notificationContext: ReturnType<typeof notification.useNotification>[1];
}

const ONE_MINUTE_IN_MS = 1000 * 60;
const FIVE_MINUTES_IN_MS = ONE_MINUTE_IN_MS * 5;
const TEN_MINUTES_IN_MS = ONE_MINUTE_IN_MS * 10;
const FIFTEEN_MINUTES_IN_MS = ONE_MINUTE_IN_MS * 15;
const THIRTY_MINUTES_IN_MS = ONE_MINUTE_IN_MS * 30;
const ONE_HOUR_IN_MS = ONE_MINUTE_IN_MS * 60;

function useSessionExpiration(): UseSessionExpirationProps {
  const { handleLogout } = useLogout();
  const { isSessionExpiring } = useSelector((state: RootState) => state.session);
  const timeoutIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();

  const { expirationTime, localExpirationTime } = useMemo(() => {
    const expiration = getExpirationTime();
    const localTime = expiration ? getTimeLocaleString(expiration) : null;
    return { expirationTime: expiration, localExpirationTime: localTime };
  }, []);

  const handleSessionExpiryCheck = () => {
    clearSessionCheckTimeout();

    const currentTime = Date.now();
    if (!expirationTime) return;

    if (currentTime >= expirationTime) {
      void handleLogout(true);
      return;
    }

    const timeUntilExpiration = expirationTime - currentTime;
    if (timeUntilExpiration <= FIFTEEN_MINUTES_IN_MS) {
      dispatch(setIsSessionExpiring(true));
      if (!isSessionExpiring) {
        showNotification();
      }
    }

    let timeUntilCheck: number = Math.min(ONE_MINUTE_IN_MS, timeUntilExpiration); // Default: check every minute
    if (timeUntilExpiration > ONE_HOUR_IN_MS) {
      timeUntilCheck = TEN_MINUTES_IN_MS; // Every 10 minutes
    } else if (timeUntilExpiration > THIRTY_MINUTES_IN_MS) {
      timeUntilCheck = FIVE_MINUTES_IN_MS; // Every 5 minutes
    }

    timeoutIdRef.current = setTimeout(handleSessionExpiryCheck, timeUntilCheck);
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      if (!timeoutIdRef.current) {
        handleSessionExpiryCheck();
      }
    } else {
      clearSessionCheckTimeout();
    }
  };

  useEffect(() => {
    handleSessionExpiryCheck();
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      clearSessionCheckTimeout();
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSessionExpiring]);

  const clearSessionCheckTimeout = () => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }
  };

  const showNotification = () => {
    if (!localExpirationTime) return;

    api.open({
      key: 'session-expiry-warning',
      message: 'Session expiry warning',
      description: `Your current session will expire at ${localExpirationTime}. Save any unsaved work to prevent any loss of progress.`,
      duration: null,
      type: 'warning',
      btn: (
        <Button type="primary" onClick={() => void handleLogout(false)}>
          Log Out
        </Button>
      ),
    });
  };

  return {
    isSessionExpiring,
    expirationTime: localExpirationTime,
    notificationContext: contextHolder,
  };
}

export default useSessionExpiration;
