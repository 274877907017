import { alpha, ThemeOptions } from '@mui/material';
import { baseMuiTheme, createAclTheme } from '@aquanow/acl/styles';

const muiTheme = createAclTheme(
  {
    theme: baseMuiTheme,
    mode: 'light',
  },
  {
    typography: {
      fontFamily: ['Inter', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: ({ theme }) => ({
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: theme.palette.background.paper,
            borderBottom: '1px solid',
            borderBottomColor: theme.palette.divider,
          }),
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: '15px',
            borderRadius: '4px',
            textTransform: 'uppercase',
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            '& .title': {
              borderRadius: '24px',
              fontWeight: 'bold',
              textTransform: 'none',
            },
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          root: {
            color: 'inherit',
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          popper: ({ theme }) => ({
            '& .MuiPaper-root': {
              backgroundColor: theme.palette.background.paper,
              '& .MuiAutocomplete-listbox': {
                padding: 0,
              },
            },
          }),
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            '&.Mui-focused': {
              color: 'inherit',
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            '& .MuiButtonBase-root': {
              textTransform: 'capitalize',
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: ({ theme }) => ({
            color: theme.palette.error.main,
            fontSize: '10px',
            marginLeft: 0,
          }),
        },
      },
      MuiDialog: {
        styleOverrides: {
          paperWidthXs: ({ theme }) => ({
            maxWidth: 600,
            padding: theme.spacing(2),
          }),
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: ({ theme }) => ({
            fontSize: 20,
            fontWeight: 600,
            padding: theme.spacing(1),
          }),
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: ({ theme }) => ({
            padding: theme.spacing(2.5, 1),
          }),
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: 0,
            color: 'inherit',
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: ({ theme }) => ({
            '&:hover': {
              backgroundColor: alpha(theme.palette.primary.main, 0.08),
            },
            '&.Mui-selected': {
              backgroundColor: alpha(theme.palette.primary.main, 0.12),
              color: theme.palette.primary.main,
              '.MuiListItemIcon-root, .MuiTypography-root': {
                color: theme.palette.primary.main,
              },
            },
          }),
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '&.MuiDataGrid-root--densityCompact .MuiButton-text': {
            fontSize: 13,
          },
        },
      },
    },
  } as ThemeOptions,
);

export default muiTheme;
