/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addedActionItem = /* GraphQL */ `
  subscription AddedActionItem {
    addedActionItem {
      actionId
      status
      username
      actionType
      actionDescription
      internalMessage
      associatedId
      adminUser
      updatedAt
      createdAt
    }
  }
`;
