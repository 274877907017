const SESSION_EXPIRATION_STORAGE_KEY = 'expirationTime';

export const getExpirationTime = (): number | null => {
  try {
    if (!localStorage) return null;
    const expirationTime = localStorage.getItem(SESSION_EXPIRATION_STORAGE_KEY);
    return expirationTime ? parseInt(expirationTime, 10) : null;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return null;
  }
};

export const setExpirationTime = (expirationTime: number): void | undefined => {
  try {
    if (!localStorage) return;
    localStorage.setItem(SESSION_EXPIRATION_STORAGE_KEY, String(expirationTime));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
