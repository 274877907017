import { useState } from 'react';
import { Box, Drawer } from '@mui/material';
import { useSubscribeToActionItemsQuery } from '@app/services/api/actionItems';
import { useGetTransactionComplianceCountQuery } from '@services/api/transaction';
import { styled } from '@mui/material/styles';
import AppBarHeader from './AppBarHeader';
import SidebarMenu from './SidebarMenu';

const drawerWidth = 280;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: '100%',
    height: '100vh',
    flexGrow: 1,
    flex: 'auto',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

export default function GlobalHeader({ children }) {
  const { data = {} } = useGetTransactionComplianceCountQuery({
    kycStatus: ['PENDING'],
    adminApproval: ['SUSPENDED'],
  });
  const { data: actionItems = [] } = useSubscribeToActionItemsQuery();
  const [open, setOpen] = useState(() => {
    const savedSidebarMenuState = localStorage.getItem('showSidebarMenu');
    return !savedSidebarMenuState ? true : JSON.parse(savedSidebarMenuState);
  });
  const transactionComplianceCount = data?.count;
  const actionItemsCount = actionItems?.length;

  const toggle = () => {
    setOpen(!open);
    localStorage.setItem('showSidebarMenu', !open);
  };

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }));

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <AppBarHeader toggle={toggle} />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader />
        <Box mt={3}>
          <SidebarMenu
            transactionComplianceCount={transactionComplianceCount}
            actionItemsCount={actionItemsCount}
          />
        </Box>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {children}
      </Main>
    </Box>
  );
}
