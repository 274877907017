export async function updateQueryDataUserSettings(dispatch, username, data, userSettingApi) {
  dispatch(
    userSettingApi.util.updateQueryData('getUserSettings', undefined, (draft) => {
      const index = draft.list.findIndex((item) => item.username === username);
      draft.list[index] = {
        ...draft.list[index],
        ...data,
      };
    }),
  );
}
