import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { api, apiBasepath } from 'src/config';
import { query, subscribe } from '../graphql/helpers';
import { getActionItems } from '../graphql/queries';
import { addedActionItem } from '../graphql/subscriptions';
import { prepareAuthHeaders } from './helper';

const { users } = apiBasepath;
export const actionItemsApi = createApi({
  reducerPath: 'actionItemsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${api.endpoints.admin}${users}`,
    prepareHeaders: prepareAuthHeaders,
  }),
  endpoints: (builder) => ({
    getActionItems: builder.query({
      async queryFn({ startTime, endTime }) {
        const response = await query(getActionItems, {
          startTime,
          endTime,
        });
        return { data: response.data.getAllTransactions.transactions };
      },
    }),
    approveActionItem: builder.mutation({
      query: (payload) => ({
        url: `approveAction`,
        method: 'PUT',
        body: payload,
      }),
    }),
    subscribeToActionItems: builder.query({
      async queryFn() {
        const response = await query(getActionItems);
        return { data: response.data.getActionItems };
      },
      async onCacheEntryAdded(arg, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) {
        let subscription;
        try {
          await cacheDataLoaded;
          const listener = (_, params) => {
            if (params?.data?.addedActionItem) {
              const {
                data: { addedActionItem },
              } = params;
              updateCachedData((draft) => {
                const index = draft.findIndex((item) => item.actionId === addedActionItem.actionId);
                if (['DONE', 'REJECTED'].includes(addedActionItem.status)) {
                  if (index === -1) return;
                  draft.splice(index, 1);
                  return;
                }
                if (index === -1) {
                  draft.push(addedActionItem);
                } else {
                  draft.splice(index, 1, addedActionItem);
                }
              });
            }
          };
          subscription = await subscribe(addedActionItem, {}, listener);
        } catch {
          // no-op
        }
        await cacheEntryRemoved;
        if (subscription && !subscription.closed) {
          subscription.unsubscribe();
        }
      },
    }),
  }),
});

export const {
  useGetActionItemsQuery,
  useSubscribeToActionItemsQuery,
  useApproveActionItemMutation,
} = actionItemsApi;
