import { notification } from 'antd';
import { configureStore, isRejectedWithValue } from '@reduxjs/toolkit';
import { actionItemsApi } from './services/api/actionItems';
import { authEventApi } from './services/api/authevents';
import { autopilotApi } from './services/api/autopilot';
import { clientListApi } from './services/api/clientlist';
import { dashboardApi } from './services/api/dashboard';
import { executionApi } from './services/api/execution';
import { marketHistoricalApi } from './services/api/marketHistorical';
import { marketDataApi } from './services/api/marketdata';
import { paymentApi } from './services/api/payment';
import { repositoryApi } from './services/api/repository';
import { settlementApi } from './services/api/settlement';
import { snapshotApi } from './services/api/snapshot';
import { spreadControlApi } from './services/api/spreadControl';
import { transactionApi } from './services/api/transaction';
import { userSettingApi } from './services/api/userSetting';
import { bankingServicesApi } from './services/api/bankingServices';
import searchTerm from './services/slice/searchTerm';
import session from './services/slice/session';

const serializableCheck = {
  ignoredActions: [executionApi.endpoints.getVariousOrders.action],
};

const rtkQueryErrorLogger = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    notification.error({
      message: action.payload.status
        ? `Network request failed: ${action.payload.status}`
        : `Operation failed.`,
      description: action.payload ? (action.payload.data || {}).message : '',
    });
  }

  if (action?.error?.message === 'No current user') {
    window?.location?.reload();
  }

  return next(action);
};

export const store = configureStore({
  reducer: {
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [actionItemsApi.reducerPath]: actionItemsApi.reducer,
    [authEventApi.reducerPath]: authEventApi.reducer,
    [autopilotApi.reducerPath]: autopilotApi.reducer,
    [clientListApi.reducerPath]: clientListApi.reducer,
    [executionApi.reducerPath]: executionApi.reducer,
    [marketDataApi.reducerPath]: marketDataApi.reducer,
    [marketHistoricalApi.reducerPath]: marketHistoricalApi.reducer,
    [snapshotApi.reducerPath]: snapshotApi.reducer,
    [transactionApi.reducerPath]: transactionApi.reducer,
    [userSettingApi.reducerPath]: userSettingApi.reducer,
    [repositoryApi.reducerPath]: repositoryApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [settlementApi.reducerPath]: settlementApi.reducer,
    searchTerm,
    session,
    [spreadControlApi.reducerPath]: spreadControlApi.reducer,
    [bankingServicesApi.reducerPath]: bankingServicesApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck }).concat(
      dashboardApi.middleware,
      actionItemsApi.middleware,
      authEventApi.middleware,
      autopilotApi.middleware,
      clientListApi.middleware,
      executionApi.middleware,
      marketDataApi.middleware,
      marketHistoricalApi.middleware,
      snapshotApi.middleware,
      transactionApi.middleware,
      userSettingApi.middleware,
      repositoryApi.middleware,
      paymentApi.middleware,
      settlementApi.middleware,
      spreadControlApi.middleware,
      bankingServicesApi.middleware,
      rtkQueryErrorLogger,
    ),
});
