import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { api, apiBasepath } from 'src/config';
import { prepareAuthHeaders } from './helper';

const { users } = apiBasepath;
export const spreadControlApi = createApi({
  reducerPath: 'spreadControlApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${api.endpoints.admin}${users}`,
    prepareHeaders: prepareAuthHeaders,
  }),
  endpoints: (builder) => ({
    getSpreadSettings: builder.query({
      query: (params) => ({
        url: `/quoteSpread`,
        params,
        method: 'GET',
      }),
    }),
    applyMultiplier: builder.mutation({
      query: (payload) => ({
        url: '/quoteSpread/multiply',
        method: 'PUT',
        body: payload,
      }),
    }),
    updateSpreadSettings: builder.mutation({
      query: (payload) => ({
        url: '/quoteSpread/update',
        method: 'PUT',
        body: payload,
      }),
    }),
    deleteSpreadSettings: builder.mutation({
      query: (payload) => ({
        url: `/quoteSpread`,
        method: 'DELETE',
        body: payload,
      }),
    }),
  }),
});

export const {
  useLazyGetSpreadSettingsQuery,
  useApplyMultiplierMutation,
  useUpdateSpreadSettingsMutation,
  useDeleteSpreadSettingsMutation,
} = spreadControlApi;
