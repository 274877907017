import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { api } from 'src/config';
import { prepareAuthHeaders } from './helper';

const { Parser } = require('json2csv');
const parser = new Parser({ quote: '' });

export const snapshotApi = createApi({
  reducerPath: 'snapshotApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${api.endpoints.admin}/users`,
    prepareHeaders: prepareAuthHeaders,
  }),
  keepUnusedDataFor: 10,
  endpoints: (builder) => ({
    getUBSnapshot: builder.query({
      query: (query) => ({
        url: `/getSnapshot?date=${query.date}`,
        method: 'GET',
      }),
    }),
    getUserBalanceConfirmation: builder.mutation({
      query: (payload) => ({
        url: `/getSnapshot`,
        method: 'POST',
        body: payload,
      }),
    }),
    getAquanowBalances: builder.query({
      query: (params) => ({
        url: `/getSnapshot?date=${params.date}`,
        method: 'GET',
      }),
      transformResponse: (res, _, params) => {
        return {
          reportType: 'Balances',
          source: 'Aquanow',
          date: params.date,
          frequency: 'Daily',
          data: parser.parse(
            res.map((balance) => {
              const { username, symbol, creditBalance, totalBalance } = balance;
              return {
                username,
                symbol,
                creditBalance,
                totalBalance,
              };
            }),
          ),
        };
      },
    }),
    getUserLedger: builder.mutation({
      query: (payload) => ({
        url: `/userLedger`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetUBSnapshotQuery,
  useGetAquanowBalancesQuery,
  useGetUserLedgerMutation,
  useGetUserBalanceConfirmationMutation,
} = snapshotApi;
