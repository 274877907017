import { store } from '@app/store';
import '../app/unregister';
import '@aws-amplify/ui-react/styles.css';
import 'antd/dist/reset.css';
import { Authenticator, ThemeProvider } from '@aws-amplify/ui-react';
import AuthHeader from '@components/AuthHeader';
import AwsCloudWatchScript from '@components/AwsCloudWatchScript';
import Layout from '@components/layout';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import { setIsSessionExpired } from '@services/slice/session';
import muiTheme from '@styles/muiTheme';
import { amplifyTheme, antdTheme } from '@styles/theme';
import colors from '@styles/variables.module.scss';
import { setExpirationTime } from '@utils/sessionExpirationStorage';
import { ConfigProvider } from 'antd';
import { Amplify } from 'aws-amplify';
import { LicenseManager } from 'ag-grid-enterprise';
import { Hub } from 'aws-amplify/utils';
import Head from 'next/head';
import NextNProgress from 'nextjs-progressbar';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { amplifyConfig } from 'src/config';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { TWELVE_HOURS_IN_MS } from 'src/constants';

LicenseManager.setLicenseKey(process.env.NEXT_PUBLIC_AG_GRID_LICENSE);
LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_DATA_GRID_PRO_LICENSE);

Amplify.configure(amplifyConfig, { ssr: true });

function App({ Component, pageProps }) {
  useEffect(() => {
    const hubListenerCancel = Hub.listen('auth', (data) => {
      const { payload } = data;
      const expirationTime = Date.now() + TWELVE_HOURS_IN_MS;
      switch (payload.event) {
        case 'signedIn':
          setExpirationTime(expirationTime);
          break;
        case 'tokenRefresh_failure':
          store.dispatch(setIsSessionExpired(true));
          break;
        default:
          break;
      }
    });

    return () => {
      hubListenerCancel();
    };
  }, []);

  return (
    <>
      <Head>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      </Head>
      <AwsCloudWatchScript />
      <Provider store={store}>
        <ThemeProvider theme={amplifyTheme}>
          <ConfigProvider theme={antdTheme}>
            <MuiThemeProvider theme={muiTheme}>
              <Authenticator
                hideSignUp
                components={{
                  SignIn: {
                    Header: AuthHeader,
                  },
                }}
                variation={'modal'}
              >
                <NextNProgress color={colors.primaryColor} />
                <Layout>
                  <Component {...pageProps} />
                </Layout>
              </Authenticator>
            </MuiThemeProvider>
          </ConfigProvider>
        </ThemeProvider>
      </Provider>
    </>
  );
}

export default App;
