import Script from 'next/script';

export default function AwsCloudWatchScript() {
  const html = {
    STAGING: `!function(a,e,t,n,s,o,i,r){i=window.AwsRumClient={q:[],n:"cwr",i:"4abcfd31-a8c4-4742-96b2-bfd50f352a31",v:"1.0.0",r:"us-east-1",c:{sessionSampleRate:1,guestRoleArn:"arn:aws:iam::329446438738:role/RUM-Monitor-us-east-1-329446438738-5430710890461-Unauth",identityPoolId:"us-east-1:2e0bd49a-4fc8-412a-ac76-5d4b10274e64",endpoint:"https://dataplane.rum.us-east-1.amazonaws.com",telemetries:["performance","errors","http"],allowCookies:!0,enableXRay:!0}},window.cwr=function(a,e){i.q.push({c:a,p:e})}}();`,
    PROD: `!function(e,a,t,n,s,o,i,r){i=window.AwsRumClient={q:[],n:"cwr",i:"d7defe31-0285-423e-9346-0cb30363bdf5",v:"1.0.0",r:"us-east-1",c:{sessionSampleRate:1,guestRoleArn:"arn:aws:iam::214455517885:role/RUM-Monitor-us-east-1-214455517885-2138257041461-Unauth",identityPoolId:"us-east-1:1f21ee96-5efe-4553-9527-7b9bad18ebe3",endpoint:"https://dataplane.rum.us-east-1.amazonaws.com",telemetries:["performance","errors","http"],allowCookies:!0,enableXRay:!0}},window.cwr=function(e,a){i.q.push({c:e,p:a})}}();`,
  };
  const isLocal = process.env.NODE_ENV === 'development';
  const isEnabled = process.env.NEXT_PUBLIC_APP_ENV in html;
  return isLocal || !isEnabled ? null : (
    <>
      <Script
        id="aws-rum-client"
        dangerouslySetInnerHTML={{
          __html: html[process.env.NEXT_PUBLIC_APP_ENV],
        }}
      />
      <Script id="aws-rum-script" src="https://client.rum.us-east-1.amazonaws.com/1.0.2/cwr.js" />
    </>
  );
}
