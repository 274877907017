import { LogoutOutlined } from '@ant-design/icons';
import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, Spin } from 'antd';
import { getCurrentUser } from 'aws-amplify/auth';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';

export default function GlobalHeaderRight({ onMenuClick, isSmallScreen }) {
  const [username, setUsername] = useState('');

  useEffect(() => {
    const fetchUsername = async () => {
      const { username } = await getCurrentUser();
      setUsername(username);
    };

    fetchUsername();
  }, []);

  const items = [
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: 'Logout',
      onClick: onMenuClick,
    },
  ];
  const className = styles.right;
  return (
    <div className={className}>
      {username ? (
        <Dropdown menu={{ items }} getPopupContainer={() => document.body}>
          <span className={`${styles.action} ${styles.account}`}>
            <FontAwesomeIcon
              icon={faUser}
              className={styles.avatar}
              data-testid="logged-in-user-icon"
            />
            {!isSmallScreen && <span className={styles.name}>{username}</span>}
          </span>
        </Dropdown>
      ) : (
        <Spin size="small" style={{ marginLeft: 8, marginRight: 8 }} />
      )}
    </div>
  );
}
