import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  Divider,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Link from 'next/link';
import AquanowLogoSmall from 'public/logo/logo-aquanow-small.svg';
import AquanowLogo from 'public/logo/logo-aquanow.svg';
import { env } from 'src/config';
import { TEnvironment } from 'src/types';
import EntityBanner from './EntityBanner';
import { useLogout } from './hooks/useLogout';
import useSessionExpiration from './hooks/useSessionExpiration';
import styles from './index.module.scss';
import RightContent from './RightContent';
import SessionExpiry from './session-expiry.svg';

interface EnvironmentTagProps {
  environment: TEnvironment | 'MISSING';
}

const EnvironmentTag: React.FC<EnvironmentTagProps> = ({ environment }) => {
  const ENVIRONMENT_NAME = {
    UAE_DEV: 'UAE DEVELOPMENT',
    UAE_STAGING: 'UAE STAGING',
    UAE_PROD: 'UAE PRODUCTION',
    DEV: 'DEVELOPMENT',
    STAGING: 'STAGING',
    PROD: 'PRODUCTION',
    MISSING: 'MISSING ENVIRONMENT',
  };

  return ENVIRONMENT_NAME[environment] ? (
    <Typography variant="overline" className={styles.environmentTagText}>
      {ENVIRONMENT_NAME[environment]}
    </Typography>
  ) : null;
};

interface AppBarHeaderProps {
  toggle: () => void;
}

const AppBarHeader = ({ toggle }: AppBarHeaderProps) => {
  const { expirationTime, isSessionExpiring, notificationContext } = useSessionExpiration();
  const { handleLogout } = useLogout();
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const handleMenuClick = async ({ key }) => {
    if (key === 'logout') {
      await handleLogout(false);
    }
  };

  return (
    <AppBar position="fixed" elevation={0}>
      <EntityBanner />
      {notificationContext}
      <Box display="flex" height={56} px={2} alignItems="center">
        <IconButton aria-label="open drawer" onClick={toggle} edge="start">
          <MenuIcon sx={{ color: 'text.primary' }} />
        </IconButton>
        <Link href="/" className={styles.logo}>
          {isSmallScreen ? <AquanowLogoSmall /> : <AquanowLogo />}
        </Link>
        {isSessionExpiring && expirationTime && (
          <Tooltip
            title={`Your current session will expire at ${expirationTime}.`}
            placement="bottom-start"
          >
            <span className={styles.sessionExpiry}>
              <SessionExpiry />
            </span>
          </Tooltip>
        )}
        <Divider orientation="vertical" variant="middle" flexItem />
        <EnvironmentTag environment={env} />
        <RightContent onMenuClick={handleMenuClick} isSmallScreen={isSmallScreen} />
      </Box>
    </AppBar>
  );
};

export default AppBarHeader;
