import React from 'react';

export interface ApiEndpoints {
  wss?: string;
  wssExecution?: string;
  market?: string;
  restExecution?: string;
  marketSource?: string; // this may not even be a valid endpoint anymore
  admin?: string;
  payment?: string;
  bankingServices?: string;
}

export interface DestinationAddress {
  external: {
    fireblocks: string;
  };
}

export interface IApi {
  endpoints: ApiEndpoints;
  destinationAddress?: DestinationAddress;
}

export interface IDestinationAddress {
  external?: { fireblocks: string };
}

export type TEnvironment = 'UAE_DEV' | 'UAE_STAGING' | 'UAE_PROD' | 'DEV' | 'STAGING' | 'PROD';

export enum RegionCodes {
  UsEast1 = 'us-east-1',
  MeCentral1 = 'me-central-1',
}

interface IRegionInfo {
  name: string;
  color: string;
}

export type TRegionsMap = {
  [key in RegionCodes]: IRegionInfo;
};

type TUser = {
  isCompanyUser?: boolean;
  username: string;
  parentAccount?: string;
  accountId?: string;
  rootUser?: string;
};

export interface UserSettings {
  list: TUser[];
  count?: number;
  pagination?: {
    page: number;
    pageSize: number;
  };
}

export type MouseEventHandler = React.MouseEvent<HTMLButtonElement>;
