import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { api, apiBasepath } from 'src/config';
import { prepareAuthHeaders } from './helper';

const { services, users } = apiBasepath;
export const autopilotApi = createApi({
  reducerPath: 'autopilotApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${api.endpoints.admin}`,
    prepareHeaders: prepareAuthHeaders,
  }),
  endpoints: (builder) => ({
    createAutoPilot: builder.mutation({
      query: (payload) => ({
        url: `${users}/depositaddresses`,
        method: 'POST',
        body: payload,
      }),
    }),
    updateAutoPilot: builder.mutation({
      query: (payload) => ({
        url: `${users}/depositaddresses`,
        method: 'PUT',
        body: payload,
      }),
    }),
    deleteAutoPilot: builder.mutation({
      query: (payload) => ({
        url: `${users}/depositaddresses`,
        method: 'DELETE',
        body: payload,
      }),
    }),
    createNewAddress: builder.mutation({
      query: (payload) => ({
        url: `${services}/getnewaddrs`,
        method: 'POST',
        body: payload,
      }),
    }),
    createFireblocksAddress: builder.mutation({
      query: (payload) => ({
        url: `${services}/fireblocksAddress`,
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useCreateAutoPilotMutation,
  useDeleteAutoPilotMutation,
  useUpdateAutoPilotMutation,
  useCreateNewAddressMutation,
  useCreateFireblocksAddressMutation,
} = autopilotApi;
