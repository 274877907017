import { store } from '@app/store';
import { Heading, View } from '@aws-amplify/ui-react';
import { Alert } from '@mui/material';
import AquanowLogo from 'public/logo/logo-aquanow.svg';
import React from 'react';
import { useSelector } from 'react-redux';

type RootState = ReturnType<typeof store.getState>;

const AuthHeader = () => {
  const { isSessionExpired } = useSelector((state: RootState) => state.session);

  return (
    <View paddingTop={'2em'}>
      {isSessionExpired && (
        <Alert
          severity="info"
          variant="filled"
          sx={{
            margin: '0 2rem 2rem 2rem',
          }}
        >
          {"You've been signed out due to a session timeout."}
        </Alert>
      )}
      <View textAlign={'center'}>
        <AquanowLogo />
      </View>
      <Heading level={3} paddingTop={25} paddingLeft={'1em'}>
        Sign in to your account
      </Heading>
    </View>
  );
};

export default AuthHeader;
