import { v4 } from 'uuid';
import { coins } from 'src/config';
import { fetchAuthSession } from 'aws-amplify/auth';

export async function prepareAuthHeaders(headers) {
  try {
    const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
    const jwtToken = accessToken.toString();
    const { payload } = idToken;
    const apiKey = payload['custom:CLIENT_PORTAL_KEY'];

    headers.set('auth-token', jwtToken);
    headers.set('x-api-key', apiKey);
    headers.set('x-nonce', Date.now().toString());
    return headers;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export function sortFxSpread(spread, service) {
  return spread
    .map((item) => ({ key: `${service}-${item.symbol}`, ...item }))
    .sort((a, b) => (a.symbol > b.symbol ? 1 : -1));
}

export function transformNonMDDataQuery(res) {
  return Object.entries(res)
    .filter(([key]) => /[A-Z0-9]{2,}/.test(key))
    .map(([symbol, { name, fiat }]) => ({
      symbol,
      name: name,
      fiat: Object.keys(fiat),
    }));
}

export function transformUserBalance(list) {
  const totalUB = { username: '____Agg Balance____', key: v4() };

  coins.forEach((cx) => {
    totalUB[cx] = 0;
  });
  list
    .map((data) => ({ ...data, key: data.username }))
    .sort((a, b) => a.username.localeCompare(b.username))
    .forEach((ub) => {
      coins.forEach((c) => {
        if (typeof ub[c] === 'number') {
          totalUB[c] += ub[c];
        }
      });
    });
  coins.forEach((agCoin) => {
    const num = totalUB[agCoin].toString().split('.');
    if (num[1] && num[1].length > 3) num[1] = num[1].substring(0, 3);
    const finalBalance = num.join('.');
    totalUB[agCoin] = finalBalance;
  });
  return [totalUB, ...list];
}

// generate request url based on params object especially for array value
export function multiQueryStringParamBuilder(params) {
  let url = '';
  for (let key in params) {
    if (params[key] instanceof Array) {
      url += `&${key}=${params[key].join(`&${key}=`)}`;
    } else {
      url += `&${key}=${params[key]}`;
    }
  }
  return url.substring(1);
}

/**
 * @function queryingWallets
 * @description get the associated walletTypes
 * @param {Object} param
 * @param {string} param.symbol symbol to query
 * @param {string} param.addressType address type to query (ERC20, TRC20, or all)
 * @return {Array} an array of walletTypes
 */
export function queryingWallets({ symbol, addressType }) {
  let wallets;
  if (symbol === 'USDT') {
    if (addressType === 'ERC-20') {
      wallets = ['fireblocksUserVault', 'fireblocksLowFreq'];
    } else if (addressType === 'TRC-20') {
      wallets = ['lowFrequencyWallet', 'tmUserWallet'];
    } else if (addressType === 'All Address Types') {
      wallets = ['fireblocksUserVault', 'fireblocksLowFreq', 'lowFrequencyWallet', 'tmUserWallet'];
    }
  } else if (symbol === 'BTC') {
    wallets = ['fireblocksUserVault', 'fireblocksLowFreq', 'btcColdWallet', 'btcHotWallet'];
  } else {
    wallets = ['fireblocksUserVault', 'fireblocksLowFreq'];
  }
  return wallets;
}
