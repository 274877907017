import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { api, apiBasepath } from 'src/config';
import { v4 } from 'uuid';
import { prepareAuthHeaders } from './helper';
import { updateQueryDataUserSettings } from './userSettingHelper';

const { users } = apiBasepath;
export const userSettingApi = createApi({
  reducerPath: 'userSettingApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${api.endpoints.admin}${users}`,
    prepareHeaders: prepareAuthHeaders,
  }),
  keepUnusedDataFor: 6,
  tagTypes: ['clientList'],
  endpoints: (builder) => ({
    getCognitoUserInfo: builder.query({
      async queryFn() {
        const { tokens } = (await fetchAuthSession()) ?? {};
        const { payload } = tokens?.idToken ?? {};
        const { email, phone_number, ['cognito:username']: username } = payload ?? {};

        return { data: { username, email, phone_number } };
      },
    }),
    getAllUsers: builder.query({
      query: () => ({
        url: `/adminGetCognitoUsers`,
        method: 'GET',
      }),
      providesTags: ['clientList'],
    }),
    getUserSettings: builder.query({
      query: () => ({
        url: '/usersetting?getAll=true',
        method: 'GET',
      }),
      transformResponse: (res) => ({
        count: res.length,
        list: res.map((user) => ({ ...user, key: user.username })),
        pagination: {
          total: res.length,
          pageSize: 10,
        },
      }),
      providesTags: ['clientList'],
    }),
    getUserSettingsByUsername: builder.query({
      query: ({ username, accountId }) => {
        const accountIdQuery = accountId ? `&accountId=${accountId}` : '';
        return {
          url: `/usersetting?username=${username}${accountIdQuery}`,
          method: 'GET',
        };
      },
    }),
    getCreditAccounts: builder.query({
      query: ({ parentAccount, isPostTradeSettlementsEnabled = false }) => ({
        url: `/creditAccounts?parentAccount=${parentAccount}&isPostTradeSettlementsEnabled=${isPostTradeSettlementsEnabled}`,
        method: 'GET',
      }),
    }),
    getUserCollateralBalance: builder.query({
      query: ({ accountId, username }) => ({
        url: `/userCollateralBalance?username=${username}&accountId=${accountId}`,
        method: 'GET',
      }),
    }),
    getRootUsers: builder.query({
      query: () => ({
        url: '/rootUsers',
        method: 'GET',
      }),
    }),
    getAquaXTiers: builder.query({
      query: () => ({
        url: `/aquaXTiers`,
        method: 'GET',
      }),
    }),
    getAquaConfigByWhitelistTier: builder.query({
      query: () => ({
        url: `/config/whitelistAssetTiers`,
        method: 'GET',
      }),
    }),
    getAquaConfigSettlementSchedules: builder.query({
      query: () => ({
        url: `/config?paramName=settlementSchedule`,
        method: 'GET',
      }),

      transformResponse: (res) => res?.value,
    }),
    updateUserSetting: builder.mutation({
      query: (payload) => ({
        url: `/usersetting`,
        method: 'PUT',
        body: payload,
      }),
      async onQueryStarted({ username, accountId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            userSettingApi.util.updateQueryData('getUserSettings', undefined, (draft) => {
              const usernameToFind = accountId ? `${username}:${accountId}` : username;
              const findIndex = draft.list.findIndex((ele) => ele.username === usernameToFind);
              if (findIndex > -1) {
                draft.list[findIndex] = data;
              }
            }),
          );
        } catch (err) {
          console.log(err);
        }
      },
    }),
    updateCognitoUser: builder.mutation({
      query: (payload) => ({
        url: `/cognitoUsers`,
        method: 'PUT',
        body: payload,
      }),
      async onQueryStarted({ username }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          await updateQueryDataUserSettings(dispatch, username, data, userSettingApi);
        } catch (err) {
          console.error(err);
        }
      },
    }),
    updateUserCheckoutPageTheme: builder.mutation({
      query: (payload) => ({
        url: `/config/checkoutPageLook`,
        method: 'PUT',
        body: payload,
      }),
    }),
    getUserCheckoutPageTheme: builder.query({
      query: ({ username }) => ({
        url: `/config?paramName=checkoutPageLook_${username}`,
        method: 'GET',
      }),
    }),
    updateChildUserSetting: builder.mutation({
      query: (obj) => ({
        url: `/usersetting/updateChildUser`,
        method: 'PUT',
        body: obj,
      }),
      async onQueryStarted({ username }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          await updateQueryDataUserSettings(dispatch, username, data, userSettingApi);
        } catch (err) {
          console.log(err);
        }
      },
    }),
    getUserWhitelist: builder.mutation({
      queryFn(username) {
        const { list } = userSettingApi.endpoints.getUserSettings.select();
        const {
          whitelist = {},
          networkFee = {},
          fiatWhitelist,
          maxTradeAmount = {},
        } = list.find((ele) => ele.username === username);
        const addr = Object.keys(whitelist);
        const mappedList = addr.map((adr) => {
          const { verified, label, symbol } = whitelist[adr];
          const { currencyTag } = whitelist[adr];
          return {
            address: adr,
            verified,
            label,
            key: v4(),
            symbol,
            currencyTag,
          };
        });
        return {
          whitelist,
          mappedList,
          fiatWhitelist,
          networkFee,
          limit: maxTradeAmount,
        };
      },
    }),
    updateUserWhitelist: builder.mutation({
      query: (payload) => ({
        url: `/usersetting/whitelist`,
        method: 'PUT',
        body: payload,
      }),
      async onQueryStarted({ username, address, verified, group }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            userSettingApi.util.updateQueryData('getUserSettings', undefined, (draft) => {
              const obj = draft.list.find((ele) => ele.username === username);
              // JULIE - non-fiats will actually return the new result upon updating
              // Fiats are being updated via a transactWrite thus, the cache will need to be updated differently
              if (group !== 'FIAT') {
                obj.whitelist = data.whitelist;
              } else {
                obj.fiatWhitelist[address].verified = verified;
              }
            }),
          );
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      },
    }),
    createNewUser: builder.mutation({
      query: (payload) => ({
        url: `/usersetting/newUser`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['clientList'],
    }),
    updateDiscountFactor: builder.mutation({
      query: (payload) => ({
        url: `/usersetting/discountFactor`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['clientList'],
    }),
    createFiatAddress: builder.mutation({
      query: (payload) => ({
        url: `/usersetting/whitelist`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['clientList'],
    }),
    deleteFiatAddress: builder.mutation({
      query: (payload) => ({
        url: `/usersetting/whitelist`,
        method: 'DELETE',
        body: payload,
      }),
      invalidatesTags: ['clientList'],
    }),
    createPostTradeSettlement: builder.mutation({
      query: (payload) => ({
        url: '/usersetting/postTradeSettlements',
        method: 'POST',
        body: payload,
      }),
      async onQueryStarted(
        {
          username,
          accountId,
          enabled,
          networkConnection,
          settlementType,
          reportGenerationTime,
          emails,
        },
        { dispatch, queryFulfilled },
      ) {
        const updateResult = dispatch(
          userSettingApi.util.updateQueryData('getUserSettings', undefined, (draft) => {
            let targetUsername = username;
            if (accountId) {
              targetUsername += `:${accountId}`;
            }
            const findIndex = draft.list.findIndex((ele) => ele.username === targetUsername);
            const existingPostTradeSettlementConfig = draft.list[findIndex].postTradeSettlements;
            draft.list[findIndex] = {
              ...draft.list[findIndex],
              postTradeSettlements: {
                ...existingPostTradeSettlementConfig,
                enabled,
                networkConnection,
                settlementType,
                reportGenerationTime,
                emails,
              },
            };
          }),
        );
        try {
          await queryFulfilled;
        } catch (err) {
          updateResult.undo();
        }
      },
    }),
    updateSettlementAddresses: builder.mutation({
      query: (payload) => ({
        url: `/usersetting/postTradeSettlements`,
        method: 'PUT',
        body: payload,
      }),
      async onQueryStarted({ username, symbol, ...rest }, { dispatch, queryFulfilled }) {
        const updateResult = dispatch(
          userSettingApi.util.updateQueryData('getUserSettings', undefined, (draft) => {
            const findIndex = draft.list.findIndex((ele) => ele.username === username);
            const existingPostTradeSettlementConfigAssetIdAddress =
              draft.list[findIndex].postTradeSettlements?.assetIdAddress?.[symbol];
            const newAssetAddressId = {
              ...draft.list[findIndex].postTradeSettlements?.assetIdAddress,
              [symbol]: {
                ...existingPostTradeSettlementConfigAssetIdAddress,
                ...rest,
              },
            };
            draft.list[findIndex] = {
              ...draft.list[findIndex],
              postTradeSettlements: {
                ...draft.list[findIndex].postTradeSettlements,
                assetIdAddress: newAssetAddressId,
              },
            };
          }),
        );
        try {
          await queryFulfilled;
        } catch (err) {
          updateResult.undo();
        }
      },
    }),
  }),
});

export const {
  useGetAquaXTiersQuery,
  useGetAquaConfigByWhitelistTierQuery,
  useGetAquaConfigSettlementSchedulesQuery,
  useGetCognitoUserInfoQuery,
  useGetUserSettingsQuery,
  useGetCreditAccountsQuery,
  useGetUserCollateralBalanceQuery,
  useGetUserSettingsByUsernameQuery,
  useGetAllUsersQuery,
  useGetRootUsersQuery,
  useUpdateUserSettingMutation,
  useUpdateCognitoUserMutation,
  useUpdateUserCheckoutPageThemeMutation,
  useGetUserCheckoutPageThemeQuery,
  useUpdateChildUserSettingMutation,
  useUpdateUserWhitelistMutation,
  useUpdateDiscountFactorMutation,
  useCreateNewUserMutation,
  useCreateFiatAddressMutation,
  useDeleteFiatAddressMutation,
  useCreatePostTradeSettlementMutation,
  useUpdateSettlementAddressesMutation,
} = userSettingApi;
