import { blue } from '@ant-design/colors';
import type { ThemeConfig } from 'antd';

export const antdTheme: ThemeConfig = {
  token: {
    zIndexPopupBase: 1202,
  },
  components: {
    Menu: {
      itemBorderRadius: 0,
      collapsedIconSize: 18,
    },
  },
};

export const amplifyTheme = {
  tokens: {
    colors: {
      primary: {
        10: blue[1],
        20: blue[1],
        40: blue[2],
        60: blue[4],
        80: blue[5],
        90: blue[6],
        100: blue[6],
      },
    },
  },
};
