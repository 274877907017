import { setIsSessionExpired, setIsSessionExpiring } from '@app/services/slice/session';
import { signOut } from 'aws-amplify/auth';
import { useDispatch } from 'react-redux';

export const useLogout = () => {
  const dispatch = useDispatch();

  const handleLogout = async (isSessionExpired) => {
    dispatch(setIsSessionExpiring(false));
    dispatch(setIsSessionExpired(isSessionExpired));
    signOut({ global: true }).catch((err) => console.log(err));
  };

  return { handleLogout };
};
