import PublicIcon from '@mui/icons-material/Public';
import { Box, Typography } from '@mui/material';
import { REGION, REGIONS } from 'src/config';

const EntityBanner = () => {
  return (
    <Box
      display="flex"
      width="100%"
      alignItems="center"
      justifyContent="center"
      height={32}
      bgcolor={REGIONS[REGION].color}
    >
      <PublicIcon />
      {REGIONS[REGION] ? (
        <Typography variant="subtitle2" ml={1} fontSize={12} letterSpacing={0.4}>
          {REGIONS[REGION].name}
        </Typography>
      ) : null}
    </Box>
  );
};

export default EntityBanner;
