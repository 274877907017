import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { api, apiBasepath } from 'src/config';
import { multiQueryStringParamBuilder, prepareAuthHeaders } from './helper';

const { users } = apiBasepath;

export const settlementApi = createApi({
  reducerPath: 'settlementApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${api.endpoints.admin}`,
    prepareHeaders: prepareAuthHeaders,
    paramsSerializer: multiQueryStringParamBuilder,
  }),
  tagTypes: ['SettlementBatch', 'SettlementBatchSummary'],
  endpoints: (builder) => ({
    getSettlementBatch: builder.query({
      query: (params) => {
        return {
          url: `${users}/settlementBatch`,
          method: 'GET',
          params,
        };
      },
      providesTags: ['SettlementBatch'],
    }),
    generateSettlementBatch: builder.mutation({
      query: (payload) => ({
        url: `${users}/settlementBatch/create/generateBatch`,
        method: 'POST',
        body: payload,
      }),
      providesTags: ['SettlementBatch'],
    }),
    getSettlementBatchSummary: builder.query({
      query: (settlementId) => ({
        url: `${apiBasepath.users}/settlementBatch`,
        method: 'GET',
        params: {
          settlementId,
          summary: true,
        },
      }),
      providesTags: ['SettlementBatchSummary'],
    }),
    getSettlementOrderDetails: builder.query({
      query: (settlementId) => ({
        url: `${apiBasepath.users}/settlementBatch`,
        method: 'GET',
        params: {
          settlementId,
          orders: true,
        },
      }),
    }),
    updateTradesMatched: builder.mutation({
      query: (payload) => {
        return {
          url: `${apiBasepath.users}/settlementBatch/tradesMatched`,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: ['SettlementBatch', 'SettlementBatchSummary'],
    }),
    cancelSettlementBatch: builder.mutation({
      query: (payload) => {
        return {
          url: `${apiBasepath.users}/settlementBatch/cancel`,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: ['SettlementBatch', 'SettlementBatchSummary'],
    }),
    confirmPaymentOnSettlementBatch: builder.mutation({
      query: (payload) => {
        return {
          url: `${apiBasepath.users}/settlementBatch/confirmPayment`,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: ['SettlementBatch'],
    }),
    completePaymentOnSettlementBatch: builder.mutation({
      query: (payload) => {
        return {
          url: `${apiBasepath.users}/settlementBatch/paymentComplete`,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: ['SettlementBatch', 'SettlementBatchSummary'],
    }),
    processWithdrawals: builder.mutation({
      query: (payload) => {
        return {
          url: `${apiBasepath.users}/settlementBatch/create/transactions`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: ['SettlementBatch'],
    }),
  }),
});

export const {
  useLazyGetSettlementBatchSummaryQuery,
  useLazyGetSettlementOrderDetailsQuery,
  useProcessWithdrawalsMutation,
  useGetSettlementBatchQuery,
  useLazyGetSettlementBatchQuery,
  useGenerateSettlementBatchMutation,
  useCancelSettlementBatchMutation,
  useCompletePaymentOnSettlementBatchMutation,
  useConfirmPaymentOnSettlementBatchMutation,
  useUpdateTradesMatchedMutation,
} = settlementApi;
