/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getActionItems = /* GraphQL */ `
  query GetActionItems {
    getActionItems {
      actionId
      status
      username
      actionType
      actionDescription
      internalMessage
      associatedId
      adminUser
      updatedAt
      createdAt
    }
  }
`;
