import Head from 'next/head';
import React from 'react';
import GlobalHeader from './GlobalHeader';

function Layout({ children }) {
  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <GlobalHeader>{children}</GlobalHeader>
    </>
  );
}

export default Layout;
