import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: '',
  expandAll: false,
  activePanels: [],
};

export const searchTermSlice = createSlice({
  name: 'searchTerm',
  initialState,
  reducers: {
    setSearchTerm: (state, action) => {
      state.value = action.payload;
    },
    setExpandAll: (state, action) => {
      state.expandAll = action.payload;
    },
    setActivePanels: (state, action) => {
      state.activePanels = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setExpandAll, setActivePanels, setSearchTerm } = searchTermSlice.actions;

export default searchTermSlice.reducer;
