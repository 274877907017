import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { api, apiBasepath } from 'src/config';
import { multiQueryStringParamBuilder, prepareAuthHeaders } from './helper';

const { payment } = apiBasepath;
export const paymentApi = createApi({
  reducerPath: 'paymentApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${api.endpoints.payment}${payment}`,
    prepareHeaders: prepareAuthHeaders,
    paramsSerializer: multiQueryStringParamBuilder,
  }),
  keepUnusedDataFor: 5,
  endpoints: (builder) => ({
    getBillById: builder.query({
      query: (paymentId) => ({
        url: `/payment`,
        method: 'GET',
        params: { paymentId },
      }),
    }),
    getBills: builder.mutation({
      async queryFn(arg, queryApi, extraOptions, baseQuery) {
        const payments = [];
        try {
          let token = '';
          while (token !== undefined) {
            const url = new URL('http://aquanow.io');
            for (const [key, value] of Object.entries(arg)) {
              if (value) url.searchParams.append(key, value);
            }
            if (token) url.searchParams.append('startsAfter', token);
            const res = await baseQuery(`/payment?${url.searchParams.toString()}`);
            const { data, pagination } = res.data;
            payments.push(...data);
            token = pagination?.startsAfter;
          }
          return { data: payments };
        } catch {
          return { data: [] };
        }
      },
    }),
  }),
});

export const { useGetBillsMutation, useGetBillByIdQuery } = paymentApi;
