import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { api } from 'src/config';
import { prepareAuthHeaders } from './helper';

export const repositoryApi = createApi({
  reducerPath: 'repository',
  baseQuery: fetchBaseQuery({
    baseUrl: `${api.endpoints.admin}/users`,
    prepareHeaders: prepareAuthHeaders,
  }),
  endpoints: (builder) => ({
    getBucketSnapshots: builder.query({
      query: (params) => ({
        url: `/bucketSnapshot?date=${params.date}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetBucketSnapshotsQuery } = repositoryApi;
