import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSessionExpired: false,
  isSessionExpiring: false,
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setIsSessionExpired: (state, action) => {
      state.isSessionExpired = action.payload;
    },
    setIsSessionExpiring: (state, action) => {
      state.isSessionExpiring = action.payload;
    },
  },
});

export const { setIsSessionExpired, setIsSessionExpiring } = sessionSlice.actions;

export default sessionSlice.reducer;
